import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import WpPage from "../components/WpPage"

const StoresArchivePage = ({ data, location }) => {

    const localeID = 'en_US';

    return (
        <Layout location={location} localeID={localeID} navShadow={true}>
            <Seo title="GDPR" />
            <WpPage localeID={localeID} data={data} url={location.pathname}/>
        </Layout>
    )
  }
  
export default StoresArchivePage

export const pageQuery = graphql`
    query GDPRQueryEN {
        wpPage(slug: {eq: "gdpr"}, locale: {id: {eq: "en_US"}}) {
            title
            content
        }
    }
`