import React from "react"
import { Link, navigate } from "gatsby"
import parse from "html-react-parser"

import { labels, localeUrls } from "../langs/langs"

import { lSVG } from "./ArrowLink"
import PartnersSlider from "./PartnersSlider"

const WpPage = ({ localeID, data, url }) => {
  const page = data.wpPage

  return (
    <>
      <div className="breadCrumbs">
        <div className="content center">
          <a className="aArow left" onClick={() => navigate(-1)}>
            {lSVG("#000")} {labels[localeID]["BACK"]}
          </a>
          <div className="fillFlex" />
          <Link to={localeUrls[localeID]["HOME"]}>
            {labels[localeID]["HOME"]}
          </Link>
          <div>/</div>
          <Link to={url}>{page.title}</Link>
        </div>
      </div>
      <div className="hPadSec concrete">
        <div className="content post">
          <h1 style={{ marginTop: 0 }}>{page.title}</h1>
          <article>{parse(!!page.content ? page.content : "")}</article>
        </div>
      </div>
      <PartnersSlider className="gray" localeID={localeID} />
    </>
  )
}

export default WpPage
